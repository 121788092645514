import styled, { css } from 'styled-components';
import { colors } from 'src/styles';
import { darken, grayscale } from 'polished';

export default {
  LinkButton: styled.button`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    padding: 20px 30px;
    text-decoration: none;
    background-color: ${colors.main.green};
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    user-select: none;
    border: 0px;
    outline: 0;

    &:hover {
      background-color: ${darken(0.1, colors.main.green)};
      color: white;
    }

    &:active {
      transform: scale(0.95);
    }

    &:visited {
      color: white;
    }

    ${({ secondary }) =>
      secondary &&
      css`
        &,
        &:visited {
          background-color: white;
          border: 1px solid ${colors.main.green};
          color: ${colors.main.green};
          &:hover {
            background-color: ${darken(0.1, colors.main.green)};
            color: white;
          }
        }
      `};

    ${({ disabled }) =>
      disabled &&
      css`
        &,
        &:hover,
        &:active {
          background-color: ${grayscale(colors.main.green)};
          transform: scale(1);
        }
      `}

    transition: all 0.25s ease;
  `,
};
