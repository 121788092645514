import styled from 'styled-components';
import { animated } from 'react-spring';

import { colors } from 'src/styles';
import { InnerLink } from './navigation.InnerLink';

export default {
  NavLinkContainer: styled.div``,
  NavLink: styled(InnerLink)``,
  NavLinkIndicator: styled(animated.div)`
    display: block;
    margin: 0 auto;
    position: relative;
    width: 0px;
    height: 2px;
    margin-top: 5px;
    background: ${colors.main.orange};
  `,
};
