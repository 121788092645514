import styled from 'styled-components';
import { Link } from 'gatsby';

export default {
  InnerLink: styled(Link)`
    &,
    &:visited {
      color: ${({ colors }) => colors.main};
      text-decoration: none;
    }

    &:hover {
      color: ${({ colors }) => colors.hover};
    }

    transition: all 0.25s ease;
  `,
};
