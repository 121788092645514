import styled from 'styled-components';

import { Button, TextInput } from 'src/modules/input';
import { breakpoints } from 'src/styles';
import { Heading } from 'src/modules/text';

export default {
  Content: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;

    @media only screen and (min-width: ${breakpoints.sm.max}) {
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  DetailsContainer: styled.div`
    width: 100%;
    max-width: 630px;

    @media only screen and (min-width: ${breakpoints.sm.max}) {
      width: auto;
    }
  `,
  DetailsWrapper: styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  `,
  NewsletterContainer: styled.div`
    max-width: 630px;
    margin-bottom: 70px;
    @media only screen and (min-width: ${breakpoints.sm.max}) {
      margin-bottom: 0px;
    }
  `,
  LogoContainer: styled.div`
    width: 80px;
    height: 80px;
  `,
  SocialContainer: styled.div`
    display: flex;
    flex-direction: row;
    margin: 40px 0px 30px -10px;

    span:before {
      margin: 10px;
      padding: 0px;
    }
  `,
  LinkContainer: styled.div`
    display: flex;
    flex-direction: column;

    a {
      margin: 7px 0px;
      font-weight: 500;
    }
  `,
  NewsletterHeading: styled(Heading)`
    margin-bottom: 15px;
  `,
  NewsletterDescription: styled.div``,
  TextInput: styled(TextInput)`
    margin-right: 15px;
    width: 100%;

    @media only screen and (min-width: ${breakpoints.sm.max}) {
      width: 310px;
    }
  `,
  SubscribeButton: styled(Button)``,
};
