import React from 'react';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';

import S from './navigation.NavLink.Styled';

export const NavLink = ({ className, isSelected, title, path, colors }) => {
  const style = useSpring({ from: { width: 0 }, to: { width: 30 } });

  return (
    <S.NavLinkContainer>
      <S.NavLink className={className} colors={colors} to={path}>
        {title}
      </S.NavLink>
      <S.NavLinkIndicator style={isSelected ? style : null} />
    </S.NavLinkContainer>
  );
};

NavLink.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  colors: PropTypes.shape({
    main: PropTypes.string,
    hover: PropTypes.string,
  }),
};

NavLink.defaultProps = {
  className: '',
  colors: undefined,
  isSelected: false,
};
