import React from 'react';
import { darken } from 'polished';
import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import { colors } from 'src/styles';
import Head from './layout.Head';
import Header from './layout.Header';
import Footer from './layout.Footer';
import './layout.font.css';

const GlobalStyle = createGlobalStyle`

  @keyframes spin {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  .grecaptcha-badge { 
    visibility: hidden;
  }


  button {
    font-family: 'Fira Sans', sans-serif;
  }

  body {
    margin: 0;
    color: ${colors.main.black};
    font-family: 'Fira Sans', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased !important;
  }

  a {
    &, &:visited {
      color: ${colors.main.blue};
      text-decoration: none;
    }

    &:hover {
      color: ${darken(0.1, colors.main.blue)};
    }
  }
`;

export const Layout = ({ children, headOptions, headerOptions, footerOptions }) => {
  return (
    <>
      <GlobalStyle />
      <Head {...headOptions} />
      <Header {...headerOptions} />
      <main>{children}</main>
      <Footer {...footerOptions} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headOptions: PropTypes.object,
  headerOptions: PropTypes.object,
  footerOptions: PropTypes.object,
};

Layout.defaultProps = {
  headOptions: {},
  headerOptions: {},
  footerOptions: {},
};

Layout.defaultProps = {};

