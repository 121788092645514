import React from 'react';
import PropTypes from 'prop-types';
import S from './input.Button.Styled';

/* Tip: Use as="button" property to change this link to button */
export const Button = ({ className, type, as, children, disabled, onClick, href, secondary }) => (
  <S.Button
    className={className}
    disabled={disabled}
    href={!disabled ? href : ''}
    target={!disabled ? '_blank' : ''}
    rel="noreferrer noopener"
    onClick={e => (!disabled ? onClick(e) : e.preventDefault())}
    secondary={secondary}
    type={type}
    as={as}
  >
    {children}
  </S.Button>
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  href: PropTypes.string,
  type: PropTypes.string,
  as: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  onClick: () => {},
  secondary: false,
  href: null,
  type: 'button',
  as: '',
};
