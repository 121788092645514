import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import S from './input.LinkButton.Styled';

export const LinkButton = ({ className, children, disabled, to, onClick, secondary }) => (
  <Link to={to}>
    <S.LinkButton
      className={className}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      secondary={secondary}
    >
      {children}
    </S.LinkButton>
  </Link>
);

LinkButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  secondary: PropTypes.bool,
  to: PropTypes.string,
};

LinkButton.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  onClick: () => {},
  secondary: false,
  to: '',
};
