import styled from 'styled-components';
import { colors as mainColors } from 'src/styles';

const Content = styled.div`
  margin-top: 20px;
  background: white;
  box-shadow: 0 2px 20px 5px #00000019;

  &:after {
    border: 10px solid;
    border-color: transparent transparent white;
    content: '';
    right: 20px;
    margin-left: -10px;
    position: absolute;
    top: 0px;
  }
`;

const Container = styled.div`
  position: absolute;
  bottom: 0px;
  right: -22px;
  display: none;
  min-width: 280px;
  flex-direction: column;
  transform: translateY(100%);
`;

const Title = styled.div`
  cursor: pointer;
  color: ${({ colors }) => colors.main};
  font-weight: 500;
  line-height: 1;

  span {
    color: ${({ colors }) => colors.main};

    ::before {
      margin: 0;
    }
  }

  transition: color 0.25s ease;
`;

export default {
  Dropdown: styled.div`
    position: relative;
    &:hover {
      ${Container} {
        display: flex;
      }

      ${Title} {
        color: ${({ colors }) => colors.hover};
        span {
          color: ${({ colors }) => colors.hover};
        }
      }
    }
  `,
  Devider: styled.div`
    width: 100%;
    height: 1px;
    background-color: ${mainColors.main.grey1};
  `,
  Container,
  Content,
  Title,
};
