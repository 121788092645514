import styled from 'styled-components';
import { animated } from 'react-spring';

import { breakpoints, colors } from 'src/styles';
import { InnerLink } from 'src/modules/navigation';
import { layoutStyles } from './layout.styles';

const MobileNavLink = styled(InnerLink)`
  display: block;
  &,
  &:visited {
    color: ${({ selected }) => (selected ? colors.main.blue : colors.main.black)};
    font-size: 18px;
  }

  transition: all 0.25s ease;
`;

export default {
  Header: styled.header`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  `,
  Content: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 24px;
    align-items: center;
    ${layoutStyles.pageWrap};
  `,
  Nav: styled.nav`
    display: none;

    a {
      margin: 0px 15px;
      font-weight: 500;
    }

    @media only screen and (min-width: ${breakpoints.sm.max}) {
      display: flex;
    }
  `,
  MobileNav: styled.nav`
    display: flex;

    @media only screen and (min-width: ${breakpoints.sm.max}) {
      display: none;
    }
  `,
  MobileMenu: styled(animated.div)`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.background.lightblue};
    padding-top: 50px;
    z-index: 999;
  `,
  MobileNavItem: styled.div`
    display: block;
    padding: 15px 0px;
    margin: 0px 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  `,
  MobileNavLink,
  MobileSublinkTitle: styled.div`
    position: relative;
    color: ${colors.main.black};
    font-size: 18px;

    ::after {
      content: '';
      position: absolute;
      top: 7px;
      right: 5px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 7px solid ${colors.main.black};
    }
  `,
  MobileSublinkContainer: styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  `,
  MobileSubLinksContainer: styled.div`
    margin: 0px 40px;
    overflow: hidden;
    transition: height 0.25s ease;
    border-bottom: 1px solid ${({ isOpen }) => (isOpen ? 'rgba(0, 0, 0, 0.3)' : 'transparent')};
    height: ${({ isOpen, linkCount }) => (isOpen ? `${linkCount * 50}px` : '0px')};
  `,
  MobileSubNavLink: styled(MobileNavLink)`
    margin: 0;
    border: 0;
    padding: 15px;
    font-size: 16px !important;
  `,
  CloseContainer: styled.div`
    position: absolute;
    top: 10px;
    right: 5px;
    padding: 20px;
  `,
  ActiveTag: styled.div`
    border-radius: 5px;
    border: 1px solid ${colors.main.green};
    color: ${colors.main.green};
    padding: 2px 6px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
  `,
  MobileSubNavLinkContainer: styled.div`
    display: flex;
    align-items: center;
  `,
};
