import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export const FluidImage = ({ className, image, style, imgStyle, alt }) => (
  <>
    {typeof image === 'object' && (
      <Img className={className} imgStyle={imgStyle} style={style} fluid={image.childImageSharp.fluid} alt={alt} />
    )}
    {typeof image === 'string' && (
      <img className={className} src={image} style={{ width: '100%', ...style }} alt={alt} />
    )}
  </>
);

FluidImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  alt: PropTypes.string,
};

FluidImage.defaultProps = {
  className: '',
  alt: '',
  imgStyle: {},
  style: {},
};
