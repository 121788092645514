import styled from 'styled-components';

import { colors } from 'src/styles';
import { InnerLink } from './navigation.InnerLink';

export default {
  ProgramLinkContainer: styled.div`
    display: flex;
    width: 100%;
  `,
  ProgramLink: styled(InnerLink)`
    color: ${colors.main.black};
    display: block;
    margin: 15px !important;
  `,
  ActiveTag: styled.div`
    border-radius: 5px;
    background-color: ${colors.background.lightgreen};
    color: ${colors.main.green};
    padding: 3px 10px;
    font-size: 12px;
    font-weight: bold;
    margin-left: 10px;
  `,
  Title: styled.div``,
};
