import React from 'react';
import PropTypes from 'prop-types';

import { FontIcon } from 'src/modules/image';
import { colors as styleColors } from 'src/styles';
import { ProgramLink } from './navigation.ProgramLink';
import S from './navigation.NavDropdown.Styled';

export const NavDropdown = ({ source, colors }) => {
  const activeLinks = source.dropdown.filter(link => link.active);
  const nonActiveLinks = source.dropdown.filter(link => !link.active);

  return (
    <S.Dropdown colors={colors}>
      <S.Title colors={colors}>
        {source.title} <FontIcon name="down-dir" />{' '}
      </S.Title>
      <S.Container>
        <S.Content>
          {activeLinks.map(link => (
            <ProgramLink key={link.path} isActive={link.active} title={link.title} path={`${link.path}`} />
          ))}
          {activeLinks.length > 0 && nonActiveLinks.length > 0 && <S.Devider />}
          {nonActiveLinks.map(link => (
            <ProgramLink key={link.path} title={link.title} path={`${link.path}`} />
          ))}
        </S.Content>
      </S.Container>
    </S.Dropdown>
  );
};

NavDropdown.propTypes = {
  source: PropTypes.shape({
    title: PropTypes.string.isRequired,
    dropdown: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  colors: PropTypes.shape({
    main: PropTypes.string,
    hover: PropTypes.string,
  }),
};

NavDropdown.defaultProps = {
  colors: {
    main: styleColors.main.black,
    hover: styleColors.main.blue,
  },
};
