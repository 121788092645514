import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { useSpring } from 'react-spring';
/* eslint-disable import/no-extraneous-dependencies */
import { Location } from '@reach/router';
import { parseDate, isDateAfter, hasDatePassed } from 'src/modules/date';

import logoImage from 'src/assets/images/logo.svg';
import logoWhiteImage from 'src/assets/images/logo-white.svg';
import { InnerLink, NavLink, NavDropdown } from 'src/modules/navigation';
import { FontIcon } from 'src/modules/image';
import { colors } from 'src/styles';
import S from './layout.Header.Styled';

const generateProgramsDropDown = edges => {
  const programs = {};

  edges.forEach(({ node }) => {
    const page = node.frontmatter;
    if (page.templateKey === 'program-page' && page.visible) {
      const { type, slug, endDate: endDateString } = node.frontmatter;
      const endDate = parseDate(endDateString);
      const isActive = hasDatePassed(endDate);
      const menuItem = { path: `/program/${slug}`, title: type, active: isActive, endDate };
      const currentProgram = programs[type];

      if (!currentProgram) {
        programs[type] = menuItem;
      } else {
        const isProgramNewer = isDateAfter(endDate, currentProgram.endDate);
        if (isProgramNewer) {
          programs[type] = menuItem;
        }
      }
    }
  });
  return Object.values(programs);
};

const MobileSubLink = ({ link, selected }) => (
  <S.MobileSubNavLink key={link.title} to={link.path} selected={selected}>
    <S.MobileSubNavLinkContainer>
      {link.title}
      {link.active && <S.ActiveTag>ΕΝΕΡΓΟ</S.ActiveTag>}
    </S.MobileSubNavLinkContainer>
  </S.MobileSubNavLink>
);

MobileSubLink.propTypes = {
  link: PropTypes.shape({
    title: PropTypes.string,
    active: PropTypes.bool,
    path: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
};

const MobileMenu = ({ links, visible, onClose }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const indicatorAnimatedStyle = useSpring({ transform: visible ? 'translateX(0%)' : 'translateX(100%)' });

  return (
    <S.MobileMenu style={indicatorAnimatedStyle}>
      <S.CloseContainer>
        <FontIcon name="cancel" size={22} color={colors.main.black} onClick={onClose} />
      </S.CloseContainer>
      <Location>
        {({ location }) => {
          return links.map(link =>
            link.dropdown ? (
              <S.MobileSublinkContainer key={link.title}>
                <S.MobileNavItem noBorder>
                  <S.MobileSublinkTitle onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                    {link.title}
                  </S.MobileSublinkTitle>
                </S.MobileNavItem>
                <S.MobileSubLinksContainer isOpen={isMobileMenuOpen} linkCount={link.dropdown.length}>
                  {link.dropdown
                    .filter(sublink => sublink.active)
                    .map(sublink => (
                      <MobileSubLink
                        key={sublink}
                        link={sublink}
                        selected={location.pathname === sublink.path || location.pathname === `${sublink.path}/`}
                      />
                    ))}
                  {link.dropdown
                    .filter(sublink => !sublink.active)
                    .map(sublink => (
                      <MobileSubLink
                        key={sublink}
                        link={sublink}
                        selected={location.pathname === sublink.path || location.pathname === `${sublink.path}/`}
                      />
                    ))}
                </S.MobileSubLinksContainer>
              </S.MobileSublinkContainer>
            ) : (
              <S.MobileNavLink
                key={link.path}
                selected={location.pathname === link.path || location.pathname === `${link.path}/`}
                to={link.path}
              >
                <S.MobileNavItem>{link.title}</S.MobileNavItem>
              </S.MobileNavLink>
            ),
          );
        }}
      </Location>
    </S.MobileMenu>
  );
};

MobileMenu.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const Header = ({ theme }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ProgramMenuQuery {
        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter {
                templateKey
                slug
                title
                type
                visible
                endDate
              }
            }
          }
        }
      }
    `,
  );

  const links = [
    {
      title: 'Αρχική',
      path: '/',
    },
    {
      title: 'Προγράμματα',
      dropdown: generateProgramsDropDown(allMarkdownRemark.edges),
    },
    {
      title: 'Ποιοι είμαστε',
      path: '/about',
    },
  ];

  const showMenu = () => {
    setMobileMenuVisible(true);
  };

  const hideMenu = () => {
    setMobileMenuVisible(false);
  };

  return (
    <S.Header>
      <S.Content>
        <InnerLink to="/">
          <img style={{ height: 45, width: 95 }} src={theme === 'light' ? logoWhiteImage : logoImage} alt="" />
        </InnerLink>
        <S.Nav>
          <Location>
            {({ location }) => {
              return links.map(link => {
                return link.dropdown ? (
                  <NavDropdown
                    key={link.title}
                    source={link}
                    colors={theme === 'light' ? { main: '#FFFFFF', hover: colors.main.grey2 } : undefined}
                  />
                ) : (
                  <NavLink
                    key={link.title}
                    isSelected={location.pathname === link.path || location.pathname === `${link.path}/`}
                    title={link.title}
                    path={link.path}
                    colors={theme === 'light' ? { main: '#FFFFFF', hover: colors.main.grey2 } : undefined}
                  />
                );
              });
            }}
          </Location>
        </S.Nav>
        <S.MobileNav>
          <FontIcon name="menu" size={22} color={theme === 'light' ? 'white' : colors.main.black} onClick={showMenu} />
          <MobileMenu links={links} visible={mobileMenuVisible} onClose={hideMenu} />
        </S.MobileNav>
      </S.Content>
    </S.Header>
  );
};

Header.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
};
Header.defaultProps = {
  theme: 'dark',
};

export default Header;
