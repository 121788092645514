import React from 'react';
import PropTypes from 'prop-types';

import S from './navigation.ProgramLink.Styled';

export const ProgramLink = ({ className, isActive, title, path }) => {
  return (
    <S.ProgramLink className={className} to={path}>
      <S.ProgramLinkContainer>
        {title}
        {isActive && <S.ActiveTag>ΕΝΕΡΓΟ</S.ActiveTag>}
      </S.ProgramLinkContainer>
    </S.ProgramLink>
  );
};

ProgramLink.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

ProgramLink.defaultProps = {
  className: '',
  isActive: false,
};
