import styled, { css } from 'styled-components';
import { colors } from 'src/styles';
import { darken, grayscale } from 'polished';

const primaryStyle = css`
  &:hover {
    background-color: ${darken(0.1, colors.main.green)};
    color: white;
  }
  &:active {
    transform: scale(0.95);
  }
  &:visited {
    color: white;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &,
      &:active,
      &:hover {
        cursor: default;
        background-color: ${colors.main.grey2};
        transform: scale(1);
      }
    `}
`;

const secondaryStyle = css`
  &,
  &:visited {
    background-color: white;
    border: 1px solid ${({ disabled }) => (disabled ? colors.main.grey2 : colors.main.green)};
    color: ${({ disabled }) => (disabled ? colors.main.grey2 : colors.main.green)};
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: default;
      `}
  }
  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'white' : darken(0.1, colors.main.green))};
    color: ${({ disabled }) => (disabled ? colors.main.grey2 : 'white')};
  }
  &:active {
    transform: scale(${({ disabled }) => (disabled ? 1 : 0.95)});
  }
`;

export default {
  Button: styled.a`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-transform: uppercase;
    padding: 20px 30px;
    text-decoration: none;
    background-color: ${colors.main.green};
    border-radius: 30px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    user-select: none;
    border: 0px;

    ${({ secondary }) => (secondary ? secondaryStyle : primaryStyle)}

    transition: all 0.25s ease;
  `,
};
