import React from 'react';
import PropTypes from 'prop-types';

import { colors as styleColors } from 'src/styles';
import S from './navigation.InnerLink.Styled';

export const InnerLink = ({ className, to, children, colors }) => {
  return (
    <S.InnerLink colors={colors} className={className} to={to}>
      {children}
    </S.InnerLink>
  );
};

InnerLink.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  colors: PropTypes.shape({
    main: PropTypes.string,
    hover: PropTypes.string,
  }),
};

InnerLink.defaultProps = {
  className: '',
  colors: {
    main: styleColors.main.black,
    hover: styleColors.main.blue,
  },
};
