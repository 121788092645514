import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

export const FixedImage = ({ image, alt, imgStyle }) => (
  <>
    {typeof image === 'object' && <Img imgStyle={imgStyle} fixed={image.childImageSharp.fixed} alt={alt} />}
    {typeof image === 'string' && <img src={image} style={imgStyle} alt={alt} />}
  </>
);

FixedImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string,
  imgStyle: PropTypes.object,
};

FixedImage.defaultProps = {
  alt: '',
  imgStyle: {},
};
