import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { colors } from 'src/styles';
import bagLogo from 'src/assets/images/logo-bag.svg';
import { FontIcon } from 'src/modules/image';
import { InnerLink } from 'src/modules/navigation';
import { Button } from 'src/modules/input';
import { Section } from './layout.Section';
import { PageWrap } from './layout.PageWrap';
import S from './layout.Footer.Styled';

function onInputChangeSetState(setStateCallback) {
  return event => {
    const { value } = event.target;

    setStateCallback(value);
  };
}

const SubscribeForm = () => {
  const [email, setEmail] = useState('');

  return (
    <form
      action="https://gethired.us4.list-manage.com/subscribe/post?u=8ac372774a88026cb1a79bd5c&amp;id=83197edaa4"
      method="post"
      id="mc-embedded-subscribe-form"
      name="mc-embedded-subscribe-form"
      target="_blank"
      noValidate
    >
      <S.TextInput
        onChange={onInputChangeSetState(setEmail)}
        value={email}
        type="email"
        name="EMAIL"
        placeholder="Το email σου"
      />
      <Button type="submit" name="subscribe" as="button" disabled={email.length === 0}>
        Εγγραφη
      </Button>
    </form>
  );
};

const Footer = ({ backgroundColor }) => {
  return (
    <footer>
      <Section withPadding backgroundColor={backgroundColor}>
        <PageWrap>
          <S.Content>
            <S.DetailsContainer>
              <S.DetailsWrapper>
                <S.LogoContainer>
                  <img src={bagLogo} alt="logo" />
                </S.LogoContainer>
                <S.SocialContainer>
                  <a href="https://www.facebook.com/GetHiredCrete/" target="_blank" rel="noopener noreferrer">
                    <FontIcon name="facebook" size={30} color={colors.main.blue} hoverable />
                  </a>
                  <a href="https://www.instagram.com/gethiredcrete/" target="_blank" rel="noopener noreferrer">
                    <FontIcon name="instagram" size={30} color={colors.main.blue} hoverable />
                  </a>
                  <a href="https://www.linkedin.com/company/gethiredcrete" target="_blank" rel="noopener noreferrer">
                    <FontIcon name="linkedin" size={30} color={colors.main.blue} hoverable />
                  </a>
                </S.SocialContainer>
                <S.LinkContainer>
                  <InnerLink to="/terms">Πολιτική απορρήτου</InnerLink>
                  <InnerLink to="/privacy">Όροι χρήσης</InnerLink>
                </S.LinkContainer>
              </S.DetailsWrapper>
            </S.DetailsContainer>
            <S.NewsletterContainer>
              <S.NewsletterHeading>
                Θέλεις να ενημερώνεσαι για τα προγράμματά μας; Κάνε εγγραφή στο newsletter μας!
              </S.NewsletterHeading>
              <S.NewsletterDescription>
                Δεν θα σε επιβαρύνουμε με spam και δεν θα μοιραστούμε το email σου με κανέναν άλλο εκτός από την
                πλατφόρμα αποστολής email που χρησιμοποιούμε. Διάβασε περισσότερα στην Πολιτική Απορρήτου μας.
              </S.NewsletterDescription>
              <SubscribeForm />
            </S.NewsletterContainer>
          </S.Content>
        </PageWrap>
      </Section>
    </footer>
  );
};

Footer.propTypes = {
  backgroundColor: PropTypes.string,
};

Footer.defaultProps = {
  backgroundColor: 'white',
};

export default Footer;
