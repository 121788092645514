export default {
  main: {
    black: '#3e3e3e',
    grey1: '#e8e8e8',
    grey2: '#c3c3c3',
    green: '#4cbf5a',
    blue: '#4f81c7',
    orange: '#ffa085',
  },
  background: {
    lightblue: '#f5f9ff',
    lightgreen: '#eff9f0',
  },
};
